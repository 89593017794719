<template>
  <div class="c-mini-basket__container">
    <div class="c-mini-basket__cart nt-cursor-pointer d-none d-md-block text-nowrap" @click="toggleInfo">
      <i class="ph-shopping-cart nt-icon--lg"></i>
      <span class="badge rounded-pill bg-light text-dark c-mini-basket__line-count" v-show="lineCount > 0">
        {{ lineCount }}
      </span>
    </div>
    <div class="c-mini-basket__cart nt-cursor-pointer d-block d-md-none" @click="goToCheckout">
      <i class="ph-shopping-cart nt-icon--lg"></i>
      <span class="badge rounded-pill bg-light text-dark c-mini-basket__line-count" v-show="lineCount > 0">
        {{ lineCount }}
      </span>
      <div class="text-center">
        <div class="nt-extra-extra-small">
          {{ infoTitleMobile }}
        </div>
        <div class="text-nowrap nt-text--info-500" style="font-size: 0.4375rem">
          {{ currencySymbol }} {{ formattedTotalAmount }}
        </div>
      </div>
    </div>
    <div class="c-mini-basket__info shadow" v-show="isActiveInfo">
      <h5 class="text-nowrap" v-if="basketType === 'Normal'">{{ infoTitle }}</h5>
      <h5 class="text-nowrap" v-if="basketType === 'Quote'">{{ infoQuoteTitle }}</h5>

      <div class="mb-3" v-show="productList.items.length >= 6">
        <a
          :href="normalCheckoutAction.url"
          class="btn btn-primary btn-lg text-nowrap w-100 mt-3"
          v-if="basketType === 'Normal'"
        >
          {{ normalCheckoutAction.name }}
        </a>
        <a
          :href="quoteCheckoutAction.url"
          class="btn btn-primary btn-lg text-nowrap w-100 mt-3"
          v-if="basketType === 'Quote'"
        >
          {{ quoteCheckoutAction.name }}
        </a>
      </div>

      <div
        v-for="product in productList.items"
        :key="product.productCode"
        class="d-flex justify-content-between align-items-center"
      >
        <div class="d-flex align-items-center">
          <img
            :src="product.imageUrl"
            alt=""
            class="rounded c-mini-basket__info-image"
            onerror="this.onerror=null;this.src='/img/no-image.png';"
            loading="lazy"
          />
          <div class="fw-bold">{{ product.productCode }}</div>
        </div>
        <div class="nt-extra-small text-end c-mini-basket__line-prices">
          {{ product.quantity }} x
          <span class="text-nowrap">{{ currencySymbol }} {{ product.unitPrice }}</span>
          <span v-show="product.quoteDiscountPercentage > 0"><br />{{ -product.quoteDiscountPercentage }} %</span>
          <span class="text-nowrap"><br />{{ currencySymbol }} {{ product.lineTotal }}</span>
        </div>
      </div>

      <hr />

      <div class="d-flex justify-content-between">
        <div>{{ subTotalLabel }}</div>
        <div>{{ currencySymbol }} {{ formattedTotalAmount }}</div>
      </div>

      <div v-show="basketType === 'Quote' && totalDiscount !== 0">
        <div class="d-flex justify-content-between">
          <div>{{ totalDiscountLabel }}</div>
          <div>{{ currencySymbol }} {{ formattedTotalDiscount }}</div>
        </div>
      </div>

      <div v-show="basketType === 'Quote' && totalDiscount !== 0">
        <div class="d-flex justify-content-between" v-if="basketType === 'Quote'">
          <div>{{ grandTotalLabel }}</div>
          <div>{{ currencySymbol }} {{ formattedGrandTotal }}</div>
        </div>
      </div>

      <a
        :href="normalCheckoutAction.url"
        class="btn btn-primary btn-lg text-nowrap w-100 mt-3"
        v-if="basketType === 'Normal'"
      >
        {{ normalCheckoutAction.name }}
      </a>
      <a
        :href="quoteCheckoutAction.url"
        class="btn btn-primary btn-lg text-nowrap w-100 mt-3"
        v-if="basketType === 'Quote'"
      >
        {{ quoteCheckoutAction.name }}
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";
import axios from "axios";
import Multilang from "../script/utils/multilang";
import ActionUrls from "../script/utils/action-urls";
import MiniBasketData from "../composables/mini-basket-data";
import { MiniBasketResponse } from "../script/models/basket";

export default defineComponent({
  name: "MiniBasket",
  data() {
    return {
      isActiveInfo: false,
      infoTitle: Multilang.getTranslation("minibasket.button.cart", "Shopping cart"),
      infoTitleMobile: Multilang.getTranslation("minibasket.button.cart.mobile", "Cart"),
      subTotalLabel: Multilang.getTranslation("minibasket.label.subtotal", "Subtotal"),
      totalDiscountLabel: Multilang.getTranslation("minibasket.label.totaldiscount", "Discount"),
      grandTotalLabel: Multilang.getTranslation("minibasket.label.grandtotal", "Total"),
      infoQuoteTitle: Multilang.getTranslation("minibasket.button.quote", "Quote"),
    };
  },
  setup: () => {
    Multilang.load();
    ActionUrls.load();

    console.log("mini setup");

    let lineCount = MiniBasketData.lineCount;
    let totalAmount = MiniBasketData.totalAmount;
    let totalDiscount = MiniBasketData.totalDiscount;
    let grandTotal = MiniBasketData.grandTotal;
    let currencySymbol = MiniBasketData.currencySymbol;
    let productList = MiniBasketData.productList;
    let basketType = MiniBasketData.basketType;

    let normalCheckoutAction = reactive({
      name: Multilang.getTranslation("minibasket.button.cart", "Shopping cart"),
      url: ActionUrls.getUrl("basket-step-1"),
    });

    let quoteCheckoutAction = reactive({
      name: Multilang.getTranslation("minibasket.button.quote", "Quote"),
      url: ActionUrls.getUrl("basket-step-1"),
    });

    // TODO should be refactor to use shared state (a ref)
    // because the minibasket component is used twice on the page (desktop + mobile)
    // and is called the api twice to get the same data
    axios
      .get<MiniBasketResponse>("/api/basket/mini")
      .then((res) => res.data)
      .then((data) => {
        MiniBasketData.set(data);
      })
      .catch((err) => console.log(err));

    return {
      lineCount,
      totalAmount,
      currencySymbol,
      basketType,
      productList,
      normalCheckoutAction,
      quoteCheckoutAction,
      totalDiscount,
      grandTotal,
    };
  },
  methods: {
    toggleInfo() {
      this.isActiveInfo = !this.isActiveInfo;
    },
    goToCheckout() {
      window.location.href = ActionUrls.getUrl("basket-step-1");
    },
  },
  computed: {
    formattedTotalAmount(): string {
      return this.totalAmount.toFixed(2);
    },
    formattedGrandTotal(): string {
      return this.grandTotal.toFixed(2);
    },
    formattedTotalDiscount(): string {
      return (-this.totalDiscount).toFixed(2);
    },
  },
});
</script>

<style lang="scss" scoped></style>
