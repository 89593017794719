<template>
  <div class="card border-light shadow-sm">
    <div class="card-body">
      <h4>
        <span class="text-nowrap" v-if="basketType === 'Quote'">{{ quoteTotalTitle }}</span>
        <span class="text-nowrap" v-if="basketType === 'Normal'">{{ cartTotalTitle }}</span>
      </h4>

      <div class="d-flex justify-content-between">
        <span>{{ subtotalTitle }}</span>
        <span class="fw-bold text-nowrap">{{ subTotalDisplay }}</span>
      </div>

      <div v-if="step === 'Checkout'">
        <div class="mt-2" v-if="basketType !== 'Quote'">
          <span class="fw-lighter nt-extra-small">{{ priceDisclaimer }}</span>
        </div>

        <div class="mt-0" v-if="basketType === 'Quote' && quoteDiscountLines.items.length > 0">
          <div class="row mb-2 my-0">
            <div class="col-sm text-end">
              <i class="fas fa-percentage" data-bs-toggle="collapse" data-bs-target="#quote-discounts"></i>
            </div>
          </div>
          <div
            class="collapse js-quote-discountlist"
            v-bind:class="{ show: quoteDiscountLines.items.filter((l) => l.percentage != null).length > 0 }"
            id="quote-discounts"
          >
            <div v-for="line in quoteDiscountLines.items" :key="line.id" class="row mx-1 mb-1 text-nowrap">
              <div class="col-1 pe-0 form-check">
                <input
                  type="checkbox"
                  v-model="line.selected"
                  @change="changeCheckbox(line)"
                  class="form-check-input"
                />
              </div>
              <div class="nt-col-ty-7 col-8 col-sm-9 col-md-9 col-lg-10 pe-0">
                <span :title="line.description">{{ line.displayName }}</span>
              </div>
              <div class="d-none d-sm-none d-md-none d-lg-inline col-lg-1">&nbsp;</div>
              <div class="col-2 col-sm-2 col-md-2 col-lg-4 pe-0 text-nowrap">
                <input
                  type="text"
                  v-model="line.percentage"
                  :ref="'percentage' + line.id"
                  @change="changePercentage(line)"
                  @keyup="markAsDirty(line)"
                  data-toggle="popover-right"
                  :title="line.description"
                  :disabled="!line.selected"
                  v-bind:class="{ 'text-danger': line.hasError }"
                  class="form-control form-control-sm js-register-input-change-vue d-inline w-60px"
                  pattern="[.,0-9]+"
                /><span class="d-inline">&nbsp;%</span>
              </div>
            </div>
          </div>
          <div v-show="totalDiscount > 0.0">
            <div class="d-flex justify-content-between">
              <span>{{ totalDiscountLabel }}</span>
              <span class="fw-bold text-nowrap">{{ totalDiscountDisplay }}</span>
            </div>
          </div>
        </div>

        <div class="d-grid my-4">
          <a
            :href="nextAction.url"
            class="btn btn-primary js-wait-for-change-handlers"
            :class="hasDirtyFields ? 'nt-disable-pointer' : ''"
            >{{ nextAction.name }}</a
          >
        </div>
      </div>

      <div v-if="step === 'Review'">
        <div v-for="line in feeAndDiscountLines.items" :key="line.description" class="d-flex justify-content-between">
          <div class="d-flex justify-content-between">
            <span>{{ line.description }}</span>
            <span class="fw-bold text-nowrap" v-if="line.price !== 0">{{ line.currencySymbol }} {{ line.price }}</span>
          </div>
        </div>

        <div
          v-for="line in quoteDiscountLines.items.filter((l) => l.percentage != null)"
          :key="line.id"
          class="justify-content-between"
        >
          <div class="d-flex justify-content-between">
            <span>{{ line.description }}</span>
            <span class="fw-bold text-nowrap">{{ line.percentage }} %</span>
          </div>
        </div>

        <div v-show="basketType == 'Quote' && totalDiscount > 0.0">
          <div class="d-flex justify-content-between">
            <span>{{ totalDiscountLabel }}</span>
            <span class="fw-bold text-nowrap">{{ totalDiscountDisplay }}</span>
          </div>
        </div>

        <div class="d-flex justify-content-between">
          <span>{{ grandTotalLabel }}</span>
          <span class="fw-bold text-nowrap">{{ grandTotalDisplay }}</span>
        </div>

        <form :action="submitOrderAction.url" method="post" v-if="basketType === 'Normal'">
          <div class="d-grid my-4">
            <button type="submit" class="btn btn-primary" :disabled="blockBasketSubmit">
              <span>{{ submitOrderAction.name }}</span>
            </button>
          </div>
        </form>

        <form :action="submitQuoteAction.url" method="post" v-if="basketType === 'Quote'">
          <div class="d-grid my-4">
            <button type="submit" class="btn btn-primary" :disabled="blockBasketSubmit">
              <span>{{ submitQuoteAction.name }}</span>
            </button>
          </div>
        </form>
      </div>

      <div class="text-center fw-bold mb-2" v-if="basketType === 'Quote'">
        <a :href="quoteShopFurtherAction.url" class="small nt-link--underline-offset-sm">
          {{ quoteShopFurtherAction.name }}
        </a>
      </div>
    </div>
  </div>

  <div class="mt-3">
    <span class="nt-extra-small" v-if="step === 'Checkout'">{{ basketListDisclaimer }}</span>
    <span class="nt-extra-small" v-if="step === 'Review'">{{ basketReviewDisclaimer }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";
import axios from "axios";
import Multilang from "../script/utils/multilang";
import ActionUrls from "../script/utils/action-urls";
import * as Toaster from "../script/utils/toaster";
import { BasketTotalsData, QuoteDiscountLine } from "../composables/basket-totals-data";
import {
  BasketTotalsResponse,
  BasketTotalsRequestData,
  QuoteDiscountLineRequestData,
  UpdateBasketTotalsResponse,
} from "../script/models/basket";
import MiniBasketData from "../composables/mini-basket-data";

export default defineComponent({
  name: "BasketTotals",
  data() {
    return {
      postTimeoutId: 0,
      quoteTotalTitle: Multilang.getTranslation("quotebasket.price.title", "Quote total"),
      cartTotalTitle: Multilang.getTranslation("basket.price.title", "Cart total"),
      subtotalTitle: Multilang.getTranslation("basket.price.subtotal", "Subtotal"),
      priceDisclaimer: Multilang.getTranslation(
        "basket.price.disclaimer",
        "(excl.extra fees, shipping costs to be confirmed)"
      ),
      grandTotalLabel: Multilang.getTranslation("basket.price.grandtotal", "Total excl. vat"),
      totalDiscountLabel: Multilang.getTranslation("basket.price.totaldiscount", "Total discount"),
      basketListDisclaimer: Multilang.getTranslation(
        "basket.list.disclaimer",
        "This is a disclaimer. This is a disclaimer. This is a disclaimer. This is a disclaimer. This is a disclaimer. This is a disclaimer. This is a disclaimer."
      ),
      basketReviewDisclaimer: Multilang.getTranslation(
        "basket.review.disclaimer",
        "This is another disclaimer. This is a disclaimer. This is a disclaimer. This is a disclaimer. This is a disclaimer. This is a disclaimer. This is a disclaimer."
      ),
      hasDirtyFields: false,
    };
  },
  setup: (props) => {
    Multilang.load();
    ActionUrls.load();

    let quoteShopFurtherAction = reactive({
      name: Multilang.getTranslation("product.detail.quote.shop-further", "Add more"),
      url: ActionUrls.getUrl("quote-shop-further"),
    });

    let nextAction = reactive({
      name: Multilang.getTranslation("basket.steps.next", "Next"),
      url: ActionUrls.getUrl("basket-step-2"),
    });

    let submitOrderAction = reactive({
      name: Multilang.getTranslation("basket.steps.submit", "Submit order"),
      url: ActionUrls.getUrl("basket-submit"),
    });

    let submitQuoteAction = reactive({
      name: Multilang.getTranslation("basket.steps.submit.quote", "Create quote"),
      url: ActionUrls.getUrl("quote-submit"),
    });

    let subTotalDisplay = BasketTotalsData.subTotalDisplay;
    let grandTotalDisplay = BasketTotalsData.grandTotalDisplay;
    let totalDiscount = BasketTotalsData.totalDiscount;
    let totalDiscountDisplay = BasketTotalsData.totalDiscountDisplay;
    let basketType = BasketTotalsData.basketType;
    let blockBasketSubmit = BasketTotalsData.blockBasketSubmit;
    let quoteDiscountLines = BasketTotalsData.quoteDiscountLines;
    let feeAndDiscountLines = BasketTotalsData.feeAndDiscountLines;

    axios
      .get<BasketTotalsResponse>("/api/basket/totals")
      .then((res) => res.data)
      .then((data) => {
        BasketTotalsData.set(data);
      })
      .catch((err) => console.log(err));

    return {
      quoteShopFurtherAction,
      nextAction,
      submitOrderAction,
      submitQuoteAction,
      basketType,
      blockBasketSubmit,
      quoteDiscountLines,
      feeAndDiscountLines,
      subTotalDisplay,
      grandTotalDisplay,
      totalDiscountDisplay,
      totalDiscount,
    };
  },
  methods: {
    cancelPost() {
      clearTimeout(this.postTimeoutId);
      this.postTimeoutId = 0;
    },
    schedulePost() {
      if (this.postTimeoutId != 0) {
        this.cancelPost();
      }

      this.postTimeoutId = setTimeout(this.post, 1000);
    },
    post() {
      this.postTimeoutId = 0;
      let postData: BasketTotalsRequestData = { lines: [] };
      for (let item of this.quoteDiscountLines.items) {
        let line: QuoteDiscountLineRequestData = {
          id: item.id,
          percentage: item.percentage as number | null,
          selected: item.selected,
          code: item.code,
        };
        postData.lines.push(line);
      }

      axios
        .post<UpdateBasketTotalsResponse>("/api/basket/totals", postData)
        .then((res) => res.data)
        .then((data) => {
          if (data.success) {
            BasketTotalsData.set(data.basketTotals);
            MiniBasketData.set(data.miniBasket);
          } else {
            if (data.message != null) {
              Toaster.pop({
                type: "warning",
                message: data.message,
              });
            }
          }
        })
        .catch((err) => console.error(err))
        .finally(() => {
          this.hasDirtyFields = false;
        });
    },
    changeCheckbox(line: QuoteDiscountLine) {
      if (line.selected === false) {
        line.percentage = null;
        this.schedulePost();
      } else if (line.percentage == null) {
        var field: HTMLInputElement = this.$refs["percentage" + line.id] as HTMLInputElement;
        field.focus();
      }
    },
    markAsDirty(line: QuoteDiscountLine) {
      let perc = line.originalPercentage;
      if (typeof line.percentage === "string") {
        perc = parseFloat(line.percentage.replace(",", "."));
      }

      if (perc !== line.originalPercentage) {
        this.hasDirtyFields = true;
      }
    },
    changePercentage(line: QuoteDiscountLine) {
      if (typeof line.percentage === "string") {
        line.percentage = parseFloat(line.percentage.replace(",", "."));
        if (isNaN(line.percentage)) {
          line.percentage = line.originalPercentage;
        }
      }

      if (typeof line.percentage !== "number") {
        line.percentage = null;
      }

      if ((line.percentage || 0) > line.maxPercentage) {
        line.hasError = true;
        setTimeout(() => {
          line.hasError = false;
          line.percentage = line.maxPercentage;
        }, 500);
      }
      if (line.percentage !== line.originalPercentage) {
        this.schedulePost();
      } else {
        this.hasDirtyFields = false;
      }
    },
  },
  computed: {
    step(): String {
      const stepHiddenElement: HTMLInputElement = document.querySelector(
        ".js-vue--basket-totals--prop-step"
      ) as HTMLInputElement;
      return stepHiddenElement?.value || "";
    },
  },
});
</script>

<style lang="scss" scoped></style>
