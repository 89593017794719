import { createApp } from "vue";

// makes sure XMLHttpRequest is included as an HTTP header value for axios calls from within Vue components
import "./script/http/set-axios-defaults";

import MiniBasket from "./components/MiniBasket.vue";
import BasketTotals from "./components/BasketTotals.vue";

// createApp(App).mount("#app");
console.log("🚀 Main.ts running - mounting components");

if (document.getElementsByClassName("js-vue--mini-basket").length) {
  createApp(MiniBasket).mount(".js-vue--mini-basket");
}

if (document.getElementsByClassName("js-vue--mini-basket-mobile").length) {
  createApp(MiniBasket).mount(".js-vue--mini-basket-mobile");
}

if (document.getElementsByClassName("js-vue--basket-totals").length) {
  createApp(BasketTotals).mount(".js-vue--basket-totals");
}
